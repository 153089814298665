// extracted by mini-css-extract-plugin
export var allCards = "CarouselSlider-module--all-cards--ds9Ph";
export var buttonContainer = "CarouselSlider-module--button-container--qAlGn";
export var card = "CarouselSlider-module--card--kkHSa";
export var contentBlock = "CarouselSlider-module--content-block--rvJeU";
export var currentCard = "CarouselSlider-module--current-card--CUplK";
export var fullCarousel = "CarouselSlider-module--full-carousel--5OTM5";
export var icon = "CarouselSlider-module--icon--6GNt5";
export var next = "CarouselSlider-module--next--PlQrV";
export var nextButton = "CarouselSlider-module--next-button--U7Ohb";
export var nextCard = "CarouselSlider-module--next-card--7W-3K";
export var offset = "CarouselSlider-module--offset--NuAOW";
export var prev = "CarouselSlider-module--prev--R1s0i";
export var previousButton = "CarouselSlider-module--previous-button--QuhYr";
export var previousCard = "CarouselSlider-module--previous-card--+fTtr";
export var project = "CarouselSlider-module--project--F-Q4Z";
export var testimonialShort = "CarouselSlider-module--testimonial-short--Wka0w";
export var testimonialStandard = "CarouselSlider-module--testimonial-standard--pJ5yf";