// extracted by mini-css-extract-plugin
export var about = "blog-post-journey-module--about--uO3mF";
export var aboutBackground = "blog-post-journey-module--about-background--rPdQ1";
export var aboutContent = "blog-post-journey-module--about-content--hUGBo";
export var authorImage = "blog-post-journey-module--author-image--ooDwH";
export var back = "blog-post-journey-module--back--9S77G";
export var categoryContainer = "blog-post-journey-module--category-container--IagqW";
export var conclusion = "blog-post-journey-module--conclusion---wNuI";
export var embeddedForm = "blog-post-journey-module--embedded-form--UI0BZ";
export var headshotContainer = "blog-post-journey-module--headshot-container--GdYsR";
export var hero = "blog-post-journey-module--hero--mxyjM";
export var heroImage = "blog-post-journey-module--hero-image--CWpaZ";
export var introContainer = "blog-post-journey-module--intro-container--hxkVb";
export var introText = "blog-post-journey-module--intro-text--Xp7tN";
export var journeyPost = "blog-post-journey-module--journey-post--IkIRF";
export var learnMore = "blog-post-journey-module--learn-more--06kZ0";
export var learnMoreContent = "blog-post-journey-module--learn-more-content--uZSHQ";
export var learnMoreSubhead = "blog-post-journey-module--learn-more-subhead--7Q5Ki";
export var metaContainer = "blog-post-journey-module--meta-container--ist7n";
export var metaText = "blog-post-journey-module--meta-text--zWKIE";
export var noIntro = "blog-post-journey-module--no-intro--5WgMc";
export var postTitle = "blog-post-journey-module--post-title--LYgpl";
export var project = "blog-post-journey-module--project--2s4kg";
export var projectContactSubhead = "blog-post-journey-module--project-contact-subhead--Qfwi2";
export var projectImage = "blog-post-journey-module--project-image--icJpS";
export var projectLink = "blog-post-journey-module--project-link--UM7EP";
export var projectLinks = "blog-post-journey-module--project-links--4lTPU";
export var projectSidebar = "blog-post-journey-module--project-sidebar--YQdEF";
export var projectSubhead = "blog-post-journey-module--project-subhead--2vXqG";
export var reviewCarousel = "blog-post-journey-module--review-carousel--XCYFr";
export var seoHeading = "blog-post-journey-module--seo-heading--zSE1w";
export var shareBlock = "blog-post-journey-module--share-block--E7OLY";
export var shareButtonText = "blog-post-journey-module--share-button-text--Xl1y8";
export var shareLinksVertical = "blog-post-journey-module--share-links-vertical--nSTJf";
export var socialIcon = "blog-post-journey-module--social-icon--JhO6h";
export var socialLinks = "blog-post-journey-module--social-links--YO0Wb";
export var startingSalaryBanner = "blog-post-journey-module--starting-salary-banner--KmdnH";
export var startingSalarySection = "blog-post-journey-module--starting-salary-section--1AHAU";
export var startingSalarySubhead = "blog-post-journey-module--starting-salary-subhead--+AbZ9";
export var studentImage = "blog-post-journey-module--student-image--5zNQP";
export var studentName = "blog-post-journey-module--student-name--X431m";
export var summaryText = "blog-post-journey-module--summary-text--bIfX9";
export var titleContainer = "blog-post-journey-module--title-container--RH4mm";